/* This example requires Tailwind CSS v2.0+ */
import { BaseProps } from '@src/Feature/Shared/FC';
import { Moment } from 'moment';
import { Observable } from 'node_modules/rxjs/dist/types';
import { FunctionComponent, useContext, useEffect } from 'react';
import { AvailabilityContext } from '../AvailabilityContext';
import './Styles/DateFeed.less';

const icons = {
	Hotel: {
		icon: <i className='far fa-hotel'></i>,
		color: 'bg-blue-500',
	},
	'1 Bedroom': {
		icon: <i className='far fa-bed'></i>,
		color: 'bg-green-500',
	},
	'2 Bedroom': {
		icon: <i className='far fa-bed-bunk'></i>,
		color: 'bg-purple-500',
	},
	Studio: {
		icon: <i className='far fa-couch'></i>,
		color: 'bg-yellow-500',
	},
	Cancel: {
		icon: <i className='far fa-times'></i>,
		color: 'bg-red-500',
	},
	Confirm: {
		icon: <i className='far fa-check'></i>,
		color: 'bg-primary-500',
	},
};

const timeline = [
	{
		id: 1,
		date: 'Sep 20',
		datetime: '2020-09-20',
		roomType: 'Hotel',
		iconBackground: 'bg-gray-400',
	},
	{
		id: 2,
		date: 'Sep 22',
		datetime: '2020-09-22',
		roomType: 'Studio',
		iconBackground: 'bg-blue-500',
	},
	{
		id: 3,
		date: 'Sep 28',
		datetime: '2020-09-28',
		roomType: '1 Bedroom',
		iconBackground: 'bg-green-500',
	},
	{
		id: 4,
		date: 'Sep 30',
		datetime: '2020-09-30',
		roomType: 'Studio',
		iconBackground: 'bg-blue-500',
	},
	{
		id: 5,
		date: 'Oct 4',
		datetime: '2020-10-04',
		roomType: '2 Bedroom',
		iconBackground: 'bg-green-500',
	},
];
function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

interface DateFeedProps extends BaseProps {
	range: Moment[];
	subscription?: Observable<any>;
}

const DateFeed: FunctionComponent<DateFeedProps> = ({ className, range }) => {
	const { config } = useContext(AvailabilityContext);
	useEffect(() => {
		console.log(config);
	}, [config]);
	return (
		range && (
			<div className={className}>
				<div className={'flow-root'}>
					<ul className='-mb-8'>
						{timeline.map((event, eventIdx) => {
							// const Icon = icons[event.roomType]?.icon;
							return (
								<li key={event.id} className='event'>
									<div className='relative pb-8'>
										{eventIdx !== timeline.length - 1 ? (
											<span
												className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
												aria-hidden='true'
											/>
										) : null}
										<div className='relative flex space-x-3'>
											<div>
												<span
													onClick={e => console.log(event)}
													className={classNames(
														icons.Confirm.color,
														'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white event__icon--cancel'
													)}>
													{icons.Confirm.icon}
												</span>
												<span
													className={classNames(
														icons[event.roomType]?.color,
														'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white event__icon'
													)}>
													{/* {Icon && <Icon className='h-5 w-5 text-white' aria-hidden='true' />} */}
													{icons[event.roomType]?.icon}
												</span>
											</div>
											<div className='min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'>
												<div>
													<p className='text-sm text-gray-500 event__text'>
														{event.roomType}
													</p>
												</div>
												<div className='text-right text-sm whitespace-nowrap text-gray-500'>
													<time dateTime={event.datetime}>{event.date}</time>
												</div>
											</div>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		)
	);
};

export default DateFeed;
