import {formikHandler} from '@src/Feature/Pages/QualificationPage/QualificationPage.PAPI';
import {routes} from '@src/MVP/Main';
import { motion, Variant, Transition, MotionStyle } from 'framer-motion';
import { CSSProperties, FC } from 'react';
import {useHistory} from 'react-router';
import {useNext} from '../NextButton/NextButton.PAPI';
import SectionHeader from './Helpers/SectionHeader/SectionHeader.PAPI';

interface pageOptions {
	animation?: {
		variants: {
			in: Variant;
			out: Variant;
		};
		transition: Transition;
	};
	className?: string;
	heading?: string;
	subHeading?: string;
	style?: MotionStyle;
}

const Page: FC<pageOptions> = function ({ children, animation, className, heading, style, subHeading }) {
	let page;
	const history = useHistory();
	const next = useNext()
	const prev = useNext(true)
	let finalStyles =style ?  JSON.parse(JSON.stringify(style)) : {}
	finalStyles.touchAction = 'pan-y'

	if (animation) {
		const { variants, transition } = animation;
		page = (
			<motion.div
				variants={variants}
				transition={transition}
				initial='out'
				exit='out'
				onPan={(a, e) => {
					try {
						formikHandler?.submitForm()
const canChange = formikHandler && !formikHandler.isValid
console.log(canChange)
						if (canChange) return
						if (e.velocity.x > 25) {
							history.push(prev.path)
							return
						}

						if (e.velocity.x < -25) {
							history.push(next.path)
							return
						}
					}
					catch (e) {
						history.push(routes[0].path)
					}
					//@ts-ignore
					//history.push(next.path)

				}}
				animate='in'
				style={finalStyles}
				className={className}>
				{heading && <SectionHeader heading={heading} subHeading={subHeading} />}
				{children}
			</motion.div>
		);
	} else {
		page = (
			<div style={style as CSSProperties} className={className}>
				{heading && <SectionHeader heading={heading} subHeading={subHeading} />}
				{children}
			</div>
		);
	}

	return page;
};

export default Page;
