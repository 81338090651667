import { CursorClickIcon, UsersIcon } from '@heroicons/react/solid';
import { BaseProps } from '@src/Feature/Shared/FC';

import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { FunctionComponent, SyntheticEvent, useContext, useState } from 'react';
import { AvailabilityContext } from '../../Helpers/AvailabilityContext';

import './Styles/index.scss';

const { RangePicker } = DatePicker;

// <-- Import End -->

interface Props_ extends BaseProps {
	buttonAction?: (e: SyntheticEvent) => void;
	buttonText?: string;
}

const ButtonDatePicker: FunctionComponent<Props_> = ({ className, onChange, buttonAction, buttonText }) => {
	// TODO: make TS happy, define interface in AvailbilityContext.ts?
	// @ts-ignore
	const { dates, changeDates, bookingLength, extraNights, showLegend } = useContext(AvailabilityContext);

	const [value, setValue] = useState();
	const [hackValue, setHackValue] = useState();

	function setDates(v) {
		setValue(v);
		onChange(v);
	}

	const disabledDate = current => {
		if (!dates || dates.length === 0) {
			return false;
		}
		const tooLate = dates[0] && current.diff(dates[0], 'days') > bookingLength + extraNights;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') > bookingLength + extraNights;
		return tooEarly || tooLate;
	};
	const onOpenChange = open => {
		if (open) {
			//@ts-ignore
			setHackValue([]);
			changeDates([]);
		} else {
			setHackValue(undefined);
		}
	};

	const dateRender = current => {
		// TODO: move to scss, use boolean/ternary/class to apply formatting
		const extraNightFormat = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginLeft: '3px',
			width: '30px',
			height: '30px',
			borderRadius: '4px',
			backgroundColor: '#80d47d',
			color: '#fff',
		};
		let style: React.CSSProperties;
		if (dates) {
			// if "first date" is selected
			if (dates[0] && !dates[1]) {
				const difference = current.diff(dates[0], 'days');
				//before first date
				if (difference < 0 && difference >= extraNights * -1) {
					style = { ...extraNightFormat };
				}
				//after last date
				else if (difference > bookingLength && difference <= bookingLength + extraNights)
					style = { ...extraNightFormat };
			}
			//if "last date" input is selected
			else if (!dates[0] && dates[1]) {
				const difference = current.diff(dates[1], 'days');
				//after last date
				if (difference > 0 && difference <= extraNights) {
					style = { ...extraNightFormat };
				}
				//before first date
				else if (difference < bookingLength * -1 && difference >= (bookingLength + extraNights) * -1) {
					style = { ...extraNightFormat };
				}
			}
		}

		return (
			<div className='ant-picker-cell-inner calendar__date' style={style}>
				{current.date()}
			</div>
		);
	};

	// TODO:
	function onPanelChange(e) {
		if (e[0]) console.log(e[0].format());
		if (e[1]) console.log(e[1].format());
	}

	const legend = (
		<div className='booking-legend'>
			<div id='green-circle' className='booking-legend__icon booking-legend__icon--extra-nights'></div>
			<label htmlFor='green-circle'>Extra Nights</label>
		</div>
	);

	return (
		<div className={className}>
			<label htmlFor='email' className='block text-sm font-medium text-gray-700'>
				Select Date
			</label>
			<div className='mt-1 flex rounded-md shadow-sm'>
				<div className='relative flex items-stretch flex-grow focus-within:z-10'>
					<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
						<UsersIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
					</div>
					<RangePicker
						dateRender={dateRender}
						onChange={setDates}
						name='email'
						id='email'
						className='focus:ring-primary-500 focus:border-primary-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300'
						size='large'
						value={hackValue || value}
						disabledDate={disabledDate}
						onPanelChange={onPanelChange}
						onCalendarChange={val => changeDates(val)}
						onOpenChange={onOpenChange}
						renderExtraFooter={showLegend ? () => legend : undefined}
					/>
				</div>
				{buttonAction && buttonText && (
					<button
						onClick={buttonAction}
						type='button'
						className='-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500'>
						<CursorClickIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
						<span>{buttonText}</span>
					</button>
				)}
			</div>
		</div>
	);
};

export default ButtonDatePicker;
