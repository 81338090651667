import {useLocation} from "react-router";
import {parse} from 'querystring'

interface ParseParams_ { dataIndex: number }

interface DynamicDataReturn_<T> {
  parsedParams: any,
output:T
}

export function useDynamicData<T>(data: Array<T>): DynamicDataReturn_<T> {
  const location = useLocation()
  const parsedParams = parse(location.search.replace('?', ''))
  const {dataIndex} = parsedParams as unknown as ParseParams_
  const output = data[dataIndex] 
  return {parsedParams, output}
}



