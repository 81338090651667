import { cloneDeep } from 'lodash';
import { selector } from 'recoil';
import { DatrixResult } from 'todos-assets/Generators/Dev/DevGenerator';
import { Guest } from 'todos-assets/Generators/Guest/GuestInfoGenerator';
import { makeDatrix } from '../Guest/DATRIX.FMT';
import { GUEST } from '../Guest/Guest.LX';
import { CART } from './Cart.LX';

const CONFIG = {
  baseUrl: 'https://webhook.site/1241ce78-f939-43a2-93b5-7cf3e8dbccc0',
  datrixToken:
    'Q29uZ3JhdHVsYXRpb25zLCB5b3UndmUgZGVjb2RlZCB0aGUgc3NhIHRva2VuLiBEdW1iYXNzLg==',
  headers: {
    'Content-Type': 'application/json',
  },
};
const maritalStatusMap = {
  'Single Male': 'singleMale',
};

export const CALENDAR = selector({
  key: 'calendar',
  get: ({ get }) => {
    const cart = get(CART);
    const guest = get(GUEST);
    const month = new Date();
    return getCalendar(guest as any, cart, month);
  },
});

async function getCalendar(guest: Guest, cart, month): Promise<Array<DatrixResult>> {
  const { monsterPassport } = guest;
  const datrix = await makeDatrix();
  const merger = {
    maritalStatus: maritalStatusMap[monsterPassport.maritalStatus],
    income: monsterPassport.income / 1000,
  };
  const copy = cloneDeep(monsterPassport);
  const output = { ...copy, ...merger };
  //@ts-ignore
  return datrix.getCalendar(cart.destName, month, output);
}
