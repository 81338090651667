import axios from "axios";
import _addressParser from "parse-address-string";
import { stringify } from "querystring";
import { selector } from "recoil";
import { MonsterPassport } from "todos-assets/Generators/Guest/MonsterPassport";
import { Preferences } from "todos-assets/Generators/Guest/Preferences";
import { Profile } from "todos-assets/Generators/Guest/Profile";
import { CrmGuest } from "../../../../todos-assets/Generators/Guest/CrmGuestInfo";
import { versionMessage } from "../../../versioning";

// <-- Import End -->

interface address_ {
	city: string;
	country: string;
	postal_code: string;
	state: string;
	street_address1: string;
}

function addressParser(address: string): Promise<address_> {
	return new Promise((r, j) => {
		_addressParser(address, (err, parsed) => {
			if (err) {
				j(err);
			} else {
				r(parsed);
			}
		});
	});
}

const CONFIG = {
	localBaseUrl: "https://us-central1-selfserviceactivations.cloudfunc",
	baseUrl:
		"https://us-central1-selfserviceactivations.cloudfunctions.net/getGuest",
	test: "palantir",
};

interface GuestPartial {
	monsterPassport: Partial<MonsterPassport>;
	preferences?: Partial<Preferences>;
	profile: Partial<Profile>;
}

// <-- Helper End -->

export const CRM_GUEST_DATA = selector({
	key: "CRM_GUEST_DATA",
	get: async ({ get }) => {
		let { baseUrl, test } = CONFIG;
		const query = {
			hash: "PY9JSMXYQY",
			test,
		};
		if ((versionMessage as any) === "") baseUrl = CONFIG.localBaseUrl;
		const requestUrl = `${baseUrl}?${stringify(query)}`;
		const response = await axios.get<CrmGuest>(requestUrl);

		const address = await addressParser(response.data.address);
		const { city, state, street_address1: street1 } = address;
		const [firstName, lastName] = response.data.name.split(" ");
		const { data } = response;
		const {
			age,
			income,
			maritalStatus,
			email,
			spouseAge,
			zipCode: zip,
		} = data;
		const output = {
			Name: `${firstName} ${lastName}`,
			'Date of Birth': '',
			'Party size': undefined,
			'Yearly annual income': income,
			'Zip code': zip,
			'Marital status': maritalStatus
		} as any;
		return output;
	},
});
