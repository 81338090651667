/* This example requires Tailwind CSS v2.0+ */
import './LandingPage.scss'
import "./Styles/Background.css";
import { pageAnimation } from "../../../Styles/PageAnimations";
import { pageTransition } from "../../../Styles/PageTransitions";
import Page from "../../Shared/Page/Page.PAPI";
import { useDynamicData } from "@src/MVP/Hooks/UseDynamicData";
import { LandingVariants } from "./variants";
import { useStringVariables } from "@src/MVP/Hooks/UseStringVariables";
import {nanoid} from "nanoid";
import {useNext} from '@src/Feature/Shared/NextButton/NextButton.PAPI';
import {useHistory} from 'react-router';

// popbox icon is currently a react component
const dest = {
    photoUrl: 'https://via.placeholder.com/150',
    description: 'testing'
}

export interface PopBox {
    name: string;
    icon: any;
    description: string;
}

export interface LandingGridData {
    companyName: string;
    tagLine: string;
    subTitle: string;
    popBoxes: Array<PopBox>;
}

//http://localhost:3000/Landing?dataIndex=0&firstName=Mary 
export default function LandingGrid() {
    const next = useNext();
    const history = useHistory();
    const { output, parsedParams } = useDynamicData<LandingGridData>(LandingVariants);
    const { firstName } = parsedParams;
    const vars = useStringVariables(output, { firstName });

    return (
        <Page
            animation={{ variants: pageAnimation, transition: pageTransition }}
            style={{ height: "100%" }}
            className="background relative bg-white py-16 sm:py-24 lg:py-32"
        >
            <div className="bg-opacity-10 mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-primary-600 uppercase">
                    {vars?.companyName}
                </h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    {vars?.tagLine}
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                    {vars?.subTitle}
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {vars?.popBoxes.map((feature: any) => (
                            <div onClick={() => {
                                history.push(next.path)
                            }} key={feature.name} className="pt-6">
<div className="photoContainer bg-gray-50 rounded-lg px-6 pb-8">
    <img className="photo" src="https://via.placeholder.com/150"/>
                                        <h3 className="mt-20 pt-5 text-lg font-medium text-gray-900 tracking-tight">
                                            {feature.name}
                                        </h3>
                                        <p className="mt-5 text-base text-gray-500">
                                            {feature.description}
                                        </p>
                                </div>
                                
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8'>
        <h2 className='sr-only'>Products</h2>

        <div className='grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8'>
            
        </div>
      </div>
        </Page>
    );
}


function DisplayImage() {
    return (
                <img
                  src={dest.photoUrl}
                  alt='img'
                  className=''
                />
            )
}
