import { useRecoilValue } from 'recoil';
import { cartSelector } from '../../../../../../../Legacy/Store/Atoms/Cart.Atom';

// <-- Import End -->

export default function Totals() {
  const cart = useRecoilValue(cartSelector);
  return (
    <dl className='border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6'>
      <div className='flex items-center justify-between'>
        <dt className='text-sm'>Subtotal</dt>
        <dd className='text-sm font-medium text-gray-900'>{`$${cart.subTotal.toFixed(
          2
        )}`}</dd>
      </div>
      <div className='flex items-center justify-between'>
        <dt className='text-sm'>Shipping and Handling</dt>
        <dd className='text-sm font-medium text-gray-900'>{`$${cart.shipping.toFixed(
          2
        )}`}</dd>
      </div>
      <div className='flex items-center justify-between'>
        <dt className='text-sm'>Taxes/Fees</dt>
        <dd className='text-sm font-medium text-gray-900'>{`$${cart.taxes.toFixed(
          2
        )}`}</dd>
      </div>
      <div className='flex items-center justify-between border-t border-gray-200 pt-6'>
        <dt className='text-base font-medium'>Total</dt>
        <dd className='text-base font-medium text-gray-900'>{`$${cart.total.toFixed(
          2
        )}`}</dd>
      </div>
    </dl>
  );
}
