import axios from 'axios';
import moment, { Moment } from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { AvailabilityContext } from './Helpers/AvailabilityContext';
import { calendar } from '@src/Legacy/Core/QualificationEngine/Helpers/Calendar/Calendar.PAPI';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  where,
  writeBatch,
} from '@firebase/firestore';
import ButtonDatePicker from './Helpers/ButtonDatePicker/ButtonDatePicker.PAPI';
import DateFeed from './Helpers/DateFeed/DateFeed.PAPI';
import TailwindSelect from './Helpers/TailwindSelect/TailwindSelect.PAPI';
import { FIREBASE_CONFIG } from '@src/Firebase';
import { useRecoilState } from 'recoil';
import { CALENDAR } from '@src/Core/States/Cart/Calendar.FMT';
// <-- Import End -->

const db = getFirestore(FIREBASE_CONFIG);

type DateChange_ = [Moment, Moment];

interface selectOptions_ {
  id: string;
  name: string;
}

interface availbilityOptions_ {
  bookingLength?: number;
  showLegend?: boolean;
}

// <-- Variable Definition End -->

//TODO: decide where max/min nights will live
const minNights = 3;
const maxNights = 5;

// TODO: decide where bookingLength should live
const AvailabilityChecker: FunctionComponent<availbilityOptions_> = ({
  bookingLength = 3,
  showLegend,
}) => {
  //@ts-ignore
  const [config] = useState({});
  const [developers, setDevelopers] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [dates, changeDates] = useState<DateChange_>([moment(), moment()]); //Array of 2 moments, representing selected dates
  const [selectedDeveloper, changeDeveloper] = useState<selectOptions_>(null);
  const [selectedRoomType, changeRoomType] = useState<selectOptions_>(null);

  const [feedRange, setFeedRange] = useState<DateChange_>(null); //Array of 2 moments, representing dates to display in DateFeed
  //! Do we clean up these states? Maybe make all of this one object?

  //Live load data from firebase
  useEffect(() => {
    axios.get('https://us-central1-thelibrarian-5fd18.cloudfunctions.net/LoadRoomTypes');
    // onSnapshot(collection(db, 'roomTypes'), doc => {
    // 	setConfig(Object.assign({}, ...doc.docs.map(doc => ({ [doc.id]: doc.data() }))));
    // });
  }, []);

  //Map developer data to array for form
  useEffect(() => {
    let developerOptions: selectOptions_[] = [];
    for (const key in config) {
      developerOptions.push({ id: key, name: config[key].name });
    }
    setDevelopers(developerOptions);
    //TODO: Only changeDeveloper here on initial component load
    changeDeveloper(developerOptions[0]);
  }, [config]);

  //Get room types for selected developer
  useEffect(() => {
    if (selectedDeveloper && Object.keys(selectedDeveloper).length) {
      const roomTypeOptions = config[selectedDeveloper.id].roomTypes.map((r, id) => ({
        id,
        name: r.name,
      }));
      setRoomTypes(roomTypeOptions);
      changeRoomType(roomTypeOptions[0]);
    }
    //! Not sure I am super happy with 3 calls to use effect, it works and I
    //! ^ understand why, do we discuss alternatives?
  }, [config, selectedDeveloper]);

  //Get room codes for specific # of nights //! <-- Not needed, because we are making all room codes of that type unavailable,
  //! ^ Does make me think though. I don't generally like comments in code,
  //! ^ however do you think there is value in putting a small comment
  //! ^ above functions to explain why they exist?

  // TODO: Remove set reservation, 🦌Rafa, idk if you wanna save this function for somewhere else
  /*
		async function setReservation(context) {
		const { dates, selectedDeveloper: developer, selectedRoomType: type } = context;
		console.log(dates, developer, type);
		const range = calendar.getRange(...dates.map(date => date.toDate()));
		const aggregatedRange = range.map(date => {
			const finalizedDate = { ...date, moment: date.moment.toDate().getTime() };
			return { ...finalizedDate, soldOutData: [type] };
		});
		const soldOutRef = collection(db, 'soldOut');
		const oldDocs = aggregatedRange.map(date => {
			console.log(date);
			const soldOutQuery = query(soldOutRef, where('date', '==', date.moment));
			return getDocs(soldOutQuery);
		});
		const unwrappedQueries = await Promise.all(oldDocs);
		const ids = unwrappedQueries
			.map(query => {
				return query.docs.map(d => {
					return { id: d.id, data: d.data() };
				});
			})
			.flat();
		const executable = ids
			.map(id => {
				const idx = aggregatedRange.findIndex(d => {
					return d.moment === id.data.moment;
				});
				const currentAggregation = aggregatedRange.splice(idx, idx + 1);
				const newAggregation = currentAggregation.map(d => {
					return {
						...d,
						soldOutData: [...d.soldOutData, id.data.soldOutData],
						id: id.id,
					};
				});
				return newAggregation;
			})
			.flat();
		const batch = writeBatch(db);
		executable.forEach(d => {
			batch.set(doc(soldOutRef, d.id), d);
		});
		aggregatedRange.forEach(d => {
			batch.set(doc(soldOutRef, d.moment.toString()), d);
		});
		await batch.commit();
		if (dates && developer && type) console.log(context);
		else console.log('Missing Info');
	}
	*/
  function setDateRange(dates) {
    if (dates) {
      const [date1, date2]: [Moment, Moment] = dates; //date from first input
      // changeDates([date1, moment(date1).add(3, 'days')]) // update controlled input state
      setFeedRange([
        moment(date1).subtract(3, 'days'), // 3 days before first input
        moment(date2).add(3, 'days'), // 3 days after second input
      ]);
    }
  }

  // TODO: Remove useEffect, Testing/Display only
  useEffect(() => {
    if (feedRange) {
      const [feed1, feed2] = feedRange;
      console.log('Feed Range \n', feed1.format('MM-DD'), feed2.format('MM-DD'));
    }
    if (dates) {
      const [date1, date2] = dates;
      console.log('Input Range \n', date1.format('MM-DD'), date2.format('MM-DD'));
    }
  }, [feedRange]);

  return (
    <AvailabilityContext.Provider
      value={{
        config,
        dates,
        selectedDeveloper,
        selectedRoomType,
        changeDates,
        bookingLength,
        extraNights: maxNights - minNights,
        showLegend,
      }}
    >
      <div className='grid grid-cols-12 p-5'>
        <ButtonDatePicker
          onChange={setDateRange}
          className='col-span-12 gap-10 mb-5 xl:col-span-6 xl:col-start-4'
          // buttonAction={() => setReservation({ config, dates, selectedDeveloper, selectedRoomType })}
          // buttonText={'Set!'}
        />
        {developers.length > 0 && (
          <TailwindSelect
            value={selectedDeveloper || developers[0]}
            options={developers}
            onChange={changeDeveloper}
            className='col-span-6 mb-5 xl:col-span-2 xl:col-start-4'
            label='Developer'
          />
        )}
        {roomTypes.length > 0 && (
          <TailwindSelect
            value={selectedRoomType}
            onChange={changeRoomType}
            options={roomTypes}
            className='col-span-6 col-start-7 xl:col-span-2 xl:col-start-8'
            label='Room Type'
          />
        )}
        <div
          className='col-span-6 col-start-4 cols  xl:col-span-2 xl:col-start-6 mt-5 flex'
          style={{ justifyContent: 'space-around', color: '#6b728055' }}
        >
          <i className='fas fa-sort-down'></i>
          <h3 style={{ color: 'inherit' }}>Select a reservation below</h3>
          <i className='fas fa-sort-down'></i>
        </div>
        <div
          className='col-span-12 xl:col-span-6 xl:col-start-4 flex'
          style={{ justifyContent: 'space-between', color: '#6b7280' }}
        >
          <p>Accomadation Type</p>
          <p>Booking Date</p>
        </div>
        <DateFeed range={dates} className='col-span-12 xl:col-span-6 xl:col-start-4' />
      </div>
    </AvailabilityContext.Provider>
  );
};

export default AvailabilityChecker;
