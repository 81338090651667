import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useModal from './Helpers/SuccessModal/SuccessModal.PAPI';
import { Form, Formik, useField } from 'formik';

// import OrderDetails from './Helpers/OrderDetails/OrderDetails.PAPI';
// import MainCheckoutForm from './Helpers/MainCheckoutForm/MainCheckoutForm.PAPI';
import { CreditCard } from './Helpers/CreditCard/CreditCard.PAPI';
import { Addons } from './Helpers/DeliveryMethod/DeliveryMethod.PAPI';
import { SweetAlertOptions } from 'sweetalert2';
// import { OrderSummary } from './Helpers/OrderSummary/OrderSummary.PAPI';

import './Styles/checkout.less';
import { OrderSummary } from './Helpers/OrderSummary/OrderSummary.PAPI';
import AvailabilityChecker from '@src/Feature/Shared/AvailabilityChecker/AvailabilityChecker.PAPI';
import { useRecoilState } from 'recoil';
import { CALENDAR } from '@src/Core/States/Cart/Calendar.FMT';

// <-- Import End -->

const initial = {
  'card-number': '',
  city: '',
  cvc: '',
  'expiration-date': '',
  'name-on-card': '',
  'postal-code': '',
  province: '',
  addons: null,
};

function submit(v, fireModal) {
  console.log('%c Form Submitted', 'color:green', v);

  //After validated and successful post
  if (true) {
    fireModal();
  }
}

const errors: any = {};
function validate(v) {
  //! Email validation, remove from checkout?
  // if (!v.email) {
  // 	errors.email = 'Required';
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v.email)) {
  // 	errors.email = 'Invalid email address';
  // }

  let valid: boolean = Object.keys(errors).length === 0 ? true : false;
  console.log(
    `%c ${valid ? 'Form Valid' : 'Form Invalid'}`,
    `color: ${valid ? 'dark-green' : 'dark-red'}; background: ${
      valid ? '#00e520' : '#ff9390'
    }; border-radius: 10px; padding-right: 5px;`,
    errors
  );
}

// TODO: Style - Connect values with less/scss variables?
const successModal: SweetAlertOptions = {
  icon: 'success',
  title: 'Congratulations!',
  iconColor: '#fff',
  allowOutsideClick: false,
  confirmButtonColor:
    'linear-gradient(90deg, rgba(121,9,9,1) 0%, rgba(224,255,0,1) 100%)',
  background: '#72d55d',
  html: `	<div class="confirmation-summary">
				<b>Your booking has been successfully confirmed!</b><br>
				<div >
					Confirmation Summary Placeholder
				</div>
			</div>`,
};

export default function Checkout() {
  const [modal, fireModal] = useModal(successModal);

  //@ts-ignore
  const [cal] = useRecoilState(CALENDAR);
  useEffect(() => {
    console.log(cal);
  }, [cal]);
  // TODO: Style -e background doesnt go to bottom in browser view
  // TODO: Style - modal adds strip of white to right side of page

  return (
    <div className='bg-gray-50 checkout-form'>
      <div className='max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
        <Formik
          initialValues={initial}
          onSubmit={(v) => submit(v, fireModal)}
          validate={validate}
        >
          <div>
            <Form className='lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16'>
              <AvailabilityChecker bookingLength={3} showLegend={true} />

              <button
                type='submit'
                className='w-full mt-2 bg-primary-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500'
              >
                Success modal
              </button>
            </Form>
          </div>
        </Formik>
        <OrderSummary></OrderSummary>
      </div>
    </div>
  );
}
// this is  a comment
// this is another comment
