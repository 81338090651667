import { LandingGridData } from "./LandingPage.PAPI";
import {
	GiftIcon,
	GlobeIcon,
	LightBulbIcon,
	SparklesIcon,
	CheckCircleIcon,
	StarIcon,
} from "@heroicons/react/outline";

export const LandingVariants: Array<LandingGridData> = [
	{
		companyName: "Monster",
		tagLine: "A Better Way to Travel",
		subTitle: `Whale hello there {{firstName}}! It's Monster here and we tried calling you earlier today, but unfortunately weren't able to connect. But no need to fret as we've got you covered! We're running some really cool specials on select destinations and you can now book your travel dates from the comfort of your home. The best part is it's already paid for so let's get you on vacation! (relaxation/vacation emojis here if possible).`,
		popBoxes: [
			{
				name: "All Inclusives",
				icon: SparklesIcon,
				description: "Receive the hottest all-inclusive destinations ",
			},
			{
				name: "Top Shelf Brands",
				icon: CheckCircleIcon,
				description: "Get the top resorts in your desired destination.",
			},
			{
				name: "Premium Accomodations",
				icon: StarIcon,
				description:
					"Spend a few nights in that Villa you've always dreamed about.",
			},
		],
	},
];
