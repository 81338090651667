import "./App.less";
import { AnimatePresence } from "framer-motion";
import {NavBar} from "./Feature/Shared/NavBar/NavBar.PAPI";
import { Main } from "./MVP/Main";
import { versionMessage } from "./versioning";
import {nanoid} from "nanoid";
import {useEffect, useMemo, useRef, useState} from "react";

// <-- Import End -->
// make a state;
// pipe the state into appWrapper as a custom variable;
// pipe the setState into the NavBar;
// use get the height of the NavBar and pass it into set state;

export function App() {
    const [clientHeight, setClientHeight] = useState(0);
		const viewportHeight = window.innerHeight;


	const styles = useMemo(() => {
        const height = `${viewportHeight - clientHeight}px`
        return {
            height,
            touchAction: 'pan-y'
        }
    }, [viewportHeight, clientHeight]);

    useEffect(  () => {
    }, [styles])

    return (
        <AnimatePresence exitBeforeEnter>
            {versionMessage}
            <NavBar changeHeight={setClientHeight} key={nanoid()} />
            <div style={styles} className="AppWrapper">
                <Main />
            </div>
        </AnimatePresence>
    );
}
export default App;
