import { FC, useEffect, useMemo, useState } from 'react';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { useRecoilState } from 'recoil';
import { TrashIcon } from '@heroicons/react/outline';
import {
  cartSelector,
  Product_,
} from '@src/Legacy/Store/Atoms/Cart.Atom';
import _ from 'lodash';
import { wrap } from '@src/Legacy/Store/Atoms/storeHelpers';


// <-- Import End -->

interface Props_ {}

// <-- Variable Definition End -->

export const ProductDisplay: FC<Props_> = () => {
	const [cart, setCart] = useRecoilState(cartSelector);
	const [height, setHeight] = useState('0px');
	const { products } = cart;

	function removeProduct(op: Product_) {
		setCart(currentCart => {
			const newCart = _.cloneDeep(currentCart);
			const i = newCart.products.findIndex(p => p.id === op.id);
			console.log(newCart.products[i]);
			newCart.products.splice(i, 1);
			return newCart;
		});
	}

	function changeQuantity(e, op: Product_) {
		const value = e.target.value;
		setCart(currentCart => {
			const newCart = _.cloneDeep(currentCart);
			const i = newCart.products.findIndex(p => p.id === op.id);
			newCart.products[i].quantity = value;
			return newCart;
		});
	}

	function calculateHeight() {
		const el = document.getElementsByClassName('listItem')[0];
		let height = el?.clientHeight + 1;
		if (height) {
			height = height * cart.products.length;
			height = (height + 'px') as unknown as number;
			setHeight(height as unknown as string);
		}
	}
	useMemo(calculateHeight, [cart]);
	useEffect(calculateHeight, [cart]);

	return (
		<motion.ul
			animate={{ height }}
			transition={{ duration: 0.5, delay: 0.5, when: 'afterChildren' }}
			className='divide-y divide-gray-200'>
			<AnimatePresence presenceAffectsLayout>
				<AnimateSharedLayout>
					<h3 className='sr-only'>Items in your cart</h3>

					{products.map(product => (
						<motion.li
							layout
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, x: 20 }}
							transition={{ duration: 0.5 }}
							key={product.key}
							className='listItem flex py-6 px-4 sm:px-6'>
							<div className='flex-shrink-0'>
								<img src={product.imageSrc} alt={product.imageAlt} className='w-20 rounded-md' />
							</div>

							<div className='ml-6 flex-1 flex flex-col'>
								<div className='flex'>
									<div className='min-w-0 flex-1'>
										<h4 className='text-sm'>
											<a
												href={product.href}
												className='font-medium text-gray-700 hover:text-gray-800'>
												{product.category}
											</a>
										</h4>
										<p className='mt-1 text-sm text-gray-500'>{product.title}</p>
										<p className='mt-1 text-sm text-gray-500'>{product.description}</p>
									</div>

									<div className='ml-4 flex-shrink-0 flow-root'>
										<button
											onClick={wrap(removeProduct, false, product)}
											type='button'
											className='-m-2.5 bg-white p-2.5 flex items-center justify-center text-gray-400 hover:text-gray-500'>
											<span className='sr-only'>Remove</span>
											<TrashIcon className='h-5 w-5' aria-hidden='true' />
										</button>
									</div>
								</div>

								<div className='flex-1 pt-2 flex items-end justify-between'>
									<p className='mt-1 text-sm font-medium text-gray-900'>{product.priceDescription}</p>

									<div className='ml-4'>
										<label htmlFor='quantity' className='sr-only'>
											Quantity
										</label>
										<select
											value={1}
											onChange={wrap(changeQuantity, true, product)}
											id='quantity'
											name='quantity'
											className='rounded-md border border-gray-300 text-base font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm'>
											<option value={1}>1</option>
											<option value={2}>2</option>
											<option value={3}>3</option>
											<option value={4}>4</option>
											<option value={5}>5</option>
											<option value={6}>6</option>
											<option value={7}>7</option>
											<option value={8}>8</option>
										</select>
									</div>
								</div>
							</div>
						</motion.li>
					))}
				</AnimateSharedLayout>
			</AnimatePresence>
		</motion.ul>
	);
};
