/* This example requires Tailwind CSS v2.0+ */
interface SectionHeaderProps {
  heading: string;
  subHeading?: string;
}
export default function SectionHeader({ heading, subHeading }: SectionHeaderProps) {
  return (
    <div className='pl-5 pt-5 pb-5 border-b border-gray-200'>
      <h3 className='text-lg leading-6 font-medium text-gray-900'>{heading}</h3>
      <p className='text-left mt-2 max-w-4xl text-sm text-gray-500'>{subHeading}</p>
    </div>
  );
}
