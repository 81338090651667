import { FunctionComponent } from 'react';
import AvailabilityChecker from '../../Shared/AvailabilityChecker/AvailabilityChecker.PAPI';

const AdminPortal: FunctionComponent = () => {
	return (
		<>
			<AvailabilityChecker bookingLength={3} showLegend={true} />
		</>
	);
};

export default AdminPortal;
