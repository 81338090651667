interface variables {
		[key: string]:  string
}

export function useStringVariables(toReplace: any, vars: variables) {
	if (typeof toReplace === 'object') {
			const keys = Object.keys(toReplace)
		return keys.reduce(( acc , key ) => {
				const currentStr = toReplace[key]
				acc[key] = replaceStr(currentStr, vars)
				return acc
		}, {})
	}
	return replaceStr(toReplace, vars)
}


function replaceStr(str: string, vars: variables) {
				const captureRegex = /{{(.*?)}}/
				const captureGroup =captureRegex.exec(str)
	if (captureGroup) {
			const [whole, capturedText] = captureGroup
			const replaceValue = vars[capturedText]
		  const newStr = str.replace(whole, replaceValue)
			return replaceStr(newStr, vars)
	}
	return str
}
