import { createDatrix } from 'monster-datrix-engine';
import { DatrixResult } from '../../../../todos-assets/Generators/Dev/DevGenerator';
import { selector } from 'recoil';
import { Guest } from 'todos-assets/Generators/Guest/GuestInfoGenerator';
import { GUEST } from './Guest.LX';
import { cloneDeep } from 'lodash';

const CONFIG = {
  baseUrl: 'https://webhook.site/1241ce78-f939-43a2-93b5-7cf3e8dbccc0',
  datrixToken:
    'Q29uZ3JhdHVsYXRpb25zLCB5b3UndmUgZGVjb2RlZCB0aGUgc3NhIHRva2VuLiBEdW1iYXNzLg==',
  headers: {
    'Content-Type': 'application/json',
  },
};
const maritalStatusMap = {
  'Single Male': 'singleMale',
};

// <-- Variable Definition End -->

export const DATRIX_DESTINATIONS = selector<Array<DatrixResult>>({
  key: 'DATRIX',
  get: async ({ get }) => {
    const guest: any = get(GUEST);
    if (!guest) return null;
    return getDestinations(guest);
  },
});

// <-- Logic End -->

interface g {
		Name: string
		'Date of Birth': string;
		'Party size': number,
		'Yearly annual income': number,
		'Zip code': any,
		'Marital status': 'married' | 'coHab' | 'singleMale' | 'singleFemale';
}

async function getDestinations(guest: g): Promise<Array<DatrixResult>> {
  const datrix = await makeDatrix();
	const test_guest = {
  age: 49,
  spouseAge: 45,
  hasMajorCC: true,
  income: 51,
  maritalStatus: 'married',
  partySize: 2,
  zip: '23456'
	}

	const newGuest = {
		age: getAge(guest['Date of Birth']),
		income: guest['Yearly annual income'],
		maritalStatus: getMaritalStatus(guest['Marital status']),
		partySize: guest['Party size'],
		zip: guest['Zip code']
	}

  const returnItem = datrix.getDestinations(newGuest as any);
  console.log(returnItem);
  return returnItem;
}

export let _datrix: ReturnType<typeof reduceDatrixErrors>;

async function reduceDatrixErrors() {
  const { datrixToken } = CONFIG;
	console.log('pre datrix')
  const output = await createDatrix(datrixToken);
	console.log('post datrix')
  if (!output) throw new Error('Datrix engine not initialized');
  return output;
}
export async function makeDatrix() {
  const output = _datrix || (await reduceDatrixErrors());
  _datrix = output as any;
  return _datrix;
}
// <-- Helper End -->
function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

function getMaritalStatus(status: string) {
	const map = {
		'Legally Married': 'married',
		'Living Together': 'coHab',
		'Single Male': 'singleMale',
		'Single Female': 'singleFemale'
	}
	return map[status]
}
