/* This example requires Tailwind CSS v2.0+ */
import {NextButton} from '../../Shared/NextButton/NextButton.PAPI';
import './NavBar.scss'
import {nanoid} from 'nanoid'
import { useLocation, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import {FC, useRef} from 'react';

// import { useMemo } from "react"
const navigation = [
	{ name: 'Website', href: 'https://monsterrg.com/' },
	{ name: 'Facebook', href: 'https://www.facebook.com/MRGVacations/' },
	{ name: 'Blog', href: 'https://monsterrg.com/blog/' },
	{ name: 'Contact', href: 'https://monsterrg.com/contact/' },
];

interface NavBar__Props {
	changeHeight: (height: number) => void;
}

export const NavBar: FC<any> = ({changeHeight}) => {
	const ref = useRef(null)
	const location = useLocation();
	const path = location.pathname;
	const history = useHistory();
	const isBasePath = path === '/landing';
	let button = (
		<motion.div
			key='testerskeisj'
			initial={{ opacity: 0, y: 0 }}
			exit={{ opacity: 0 }}
			animate={{ opacity: 1, y: -100 }}
			style={{ display: 'inline' }}>
			<NextButton type='navBar'>
				NextButton
			</NextButton>
		</motion.div>
	);
	return (
				<header ref={newRef => {
			const navHeight = newRef?.offsetHeight;
			changeHeight(navHeight);
		}} className='sticky top-0 z-50 bg-primary-600'>
			<nav className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8' aria-label='Top'>
				<div className='w-full py-6 flex items-center justify-between border-b border-primary-500 lg:border-none'>
					<div
						className='flex items-center cursor-pointer'
						onClick={() => {
							history.push('/');
						}}
						style={{}}>
						<span className='sr-only'>Monster</span>
<img src="https://i.ibb.co/FVgBBMT/White-Word-Logo-1.png" className="logo"/>
						<div className='hidden ml-10 space-x-8 lg:block'>
							{navigation.map(link => (
								<a
									key={nanoid()}
									href={link.href}
									className='text-base font-medium text-white hover:text-primary-50'>
									{link.name}
								</a>
							))}
						</div>
					</div>
					<div className='ml-10 space-x-4'>
						<AnimatePresence>{ button}</AnimatePresence>
						<a
							href='#'
							className='inline-block bg-white likeButton py-2 px-4 border border-transparent rounded-md text-base font-medium text-primary-600 hover:bg-green-50'>
					<i  className="fad fa-thumbs-up fa-lg thumbsUp"></i><p className="text">Like us!</p>
						</a>
					</div>
				</div>
				<div className='py-4 flex flex-wrap justify-center space-x-6 lg:hidden'>
					{navigation.map(link => (
						<a
							key={nanoid()}
							href={link.href}
							className='text-base font-medium text-white hover:text-primary-50'>
							{link.name}
						</a>
					))}
				</div>
			</nav>
		</header>
	);
}
