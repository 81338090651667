import React, { useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

export default function useModal(options: SweetAlertOptions) {
	const [data, setData] = useState(null);

	function fireModal() {
		Swal.fire(options)
			.then(dat => setData(dat))
			.catch(err => setData(err));
	}

	return [data, fireModal];
}
