import { useMemo, useState } from 'react'
import '../../Styles/checkout.less'

// <-- Import End -->

// <-- Variable Definition End -->

// Formik?
//
const ccIcons = {
    amex: <i className='fab fa-cc-amex payment__card-icon'></i>,
    discover: <i className='fab fa-cc-discover payment__card-icon'></i>,
    master: <i className='fab fa-cc-mastercard payment__card-icon'></i>,
    visa: <i className='fab fa-cc-visa payment__card-icon'></i>,
}

const paymentOptions = [
    { label: 'Card', type: 'credit', icon: <i className='far fa-credit-card-front'></i> },
    { label: '', type: 'apple', icon: <i className='fab fa-apple-pay'></i> },
    { label: 'pay', type: 'android', icon: <i className='fab fa-android'></i> },
]

// TODO: Rafa - make these functions with copilot
function formatExp(date) {
    return `Expires ${date}`
}
function formatUpdated(date) {
    return `Last updated on ${date}`
}

export default function PaymentSelection() {
    const [paymentInfo, setPaymentInfo] = useState(null)

    const paymentSummary = useMemo(() => {
        // TODO: Refactor all of this gross switch
        if (paymentInfo) {
            const { type, cardType, expiration, lastFour, lastUpdated } = paymentInfo
            switch (type) {
                case 'credit':
                    return (
                        <div
                            className={`rounded-md bg-gray-50 px-4 py-5 sm:flex sm:items-start sm:justify-between payment__summary payment__summary--${type}`}>
                            <h4 className='sr-only'>{cardType}</h4>
                            <div className='sm:flex sm:items-start'>
                                {ccIcons[cardType]}
                                <div className='mt-3 sm:mt-0 sm:ml-4'>
                                    <div className='text-sm font-medium text-gray-900'>{`Ending with ${lastFour}`}</div>
                                    <div className='mt-1 text-sm text-gray-600 sm:flex sm:items-center'>
                                        <div>{formatExp(expiration)}</div>
                                        <span className='hidden sm:mx-2 sm:inline' aria-hidden='true'>
                                            &middot;
                                        </span>
                                        <div className='mt-1 sm:mt-0'>{formatUpdated(lastUpdated)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0'>
                                <button
                                    type='button'
                                    onClick={e => clearSelection(e)}
                                    className='inline-flex items-center px-4 py-2 border top-5 border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'>
                                    <i className='fal fa-times'></i>
                                </button>
                            </div>
                        </div>
                    )
                case 'apple':
                    return (
                        <div className={`rounded-md bg-gray-50 px-4 py-5 payment__summary payment__summary--${type}`}>
                            <div className='sm:ml-6 sm:flex-shrink-0'>
                                <h4 className='sr-only'>{cardType}</h4>
                                <div className='flex items-center'>
                                    <i className='fab fa-apple mr-2'></i>
                                    <p className='mb-0'>Successfully Added!</p>
                                </div>
                                <button
                                    type='button'
                                    onClick={e => clearSelection(e)}
                                    className='inline-flex items-center px-4 py-2 border top-5 border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'>
                                    <i className='fal fa-times'></i>
                                </button>
                            </div>
                        </div>
                    )

                case 'android':
                    return (
                        <div
                            className={`rounded-md bg-gray-50 px-4 py-5 sm:flex sm:items-start sm:justify-between payment__summary payment__summary--${type}`}>
                            <div className='sm:ml-6 sm:flex-shrink-0'>
                                <h4 className='sr-only'>{cardType}</h4>
                                <div className='flex items-center'>
                                    <i className='fab fa-android mr-2'></i>
                                    <p className='mb-0'>Successfully Added!</p>
                                </div>
                                <button
                                    type='button'
                                    onClick={e => clearSelection(e)}
                                    className='inline-flex items-center px-4 py-2 border top-5 border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'>
                                    <i className='fal fa-times'></i>
                                </button>
                            </div>
                        </div>
                    )
                    break
                default:
                    break
            }
        }
    }, [paymentInfo])

    function handlePaymentSelect(e, type) {
        e.preventDefault()

        switch (type) {
            case 'credit':
                //add auth nonsense here
                alert('Add credit card stuff here')
                //! Testing/Demo Only
                const random = (min, max) => Math.floor(Math.random() * (max - min) + min)
                const randomName = random(0, 1) ? 'Jane Doe' : 'John Doe'
                const randomCard = Object.keys(ccIcons)[Math.floor(Math.random() * Object.keys(ccIcons).length)]
                const randomFour = random(1000, 9999)
                const randomExp = `${random(1, 12)}/${random(21, 24)}`
                const randomUpdate = `${random(0, 28)} ${['Jan', 'Mar', 'May', 'Jul', 'Sep', 'Nov'][random(0, 5)]
                    } 1964`
                //! ^^^ Testing/Demo Only

                // TODO: use timestamps for lastUpdate & expiration, finish formatfunctions for formatting to string
                setPaymentInfo({
                    type,
                    cardType: randomCard,
                    name: randomName,
                    lastFour: randomFour,
                    expiration: randomExp,
                    lastUpdated: randomUpdate,
                })
                break
            case 'apple':
                //add auth nonsense here
                alert('Add apple stuff here')
                setPaymentInfo({
                    type,
                })
                break
            case 'android':
                //add auth nonsense here
                alert('Add android stuff here')
                setPaymentInfo({
                    type,
                })
                break
            default:
                break
        }
    }

    function clearSelection(e) {
        e.preventDefault()
        // TODO: Add confirmation modal
        setPaymentInfo(null)
    }

    return (
        <div className='mt-10 border-t border-gray-200 pt-5'>
            <div className='bg-white shadow sm:rounded-lg'>
                <div className='px-4 py-5 sm:p-6'>
                    <h3 className='text-lg leading-6 font-medium text-gray-900'>Payment method</h3>
                    <div className='mt-5 payment'>
                        {!paymentInfo &&
                            paymentOptions.map(option => {
                                return (
                                    <button
                                        onClick={e => handlePaymentSelect(e, option.type)}
                                        className={`payment__option payment__option--${option.type}`}>
                                        {option.icon}
                                        <p>{option.label}</p>
                                    </button>
                                )
                            })}

                        {paymentInfo && paymentSummary}
                    </div>
                </div>
            </div>
        </div>
    )
}
