import { atom } from 'recoil';

interface ShoppingCart {
  destination: string;
  maxNights: number;
  minNights: number;
}

export const CART = atom<ShoppingCart>({
  key: 'cart',
  default: {} as ShoppingCart,
});
