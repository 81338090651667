import { FC } from 'react';
import { CreditCard } from '../CreditCard/CreditCard.PAPI'
import PaymentSelection from '../PaymentSelection'
import { ProductDisplay } from './Helpers/ProductDisplay/ProductDisplay.PAPI';
import Totals from './Helpers/Totals/Totals.PAPI';

// <-- Import End -->

// <-- Variable Definition End -->

export const OrderSummary: FC<any> = () => {
  return (
    <div className='mt-10 lg:mt-0'>
      <h2 className='text-lg font-medium text-gray-900'>Order summary</h2>

      <div className='mt-4 bg-white border border-gray-200 rounded-lg shadow-sm'>
        <ProductDisplay />
        <PaymentSelection/>
        <Totals />
        <div className='border-t border-gray-200 py-6 px-4 sm:px-6'>
          <button
            type='submit'
            className='w-full bg-primary-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500'
          >
            Confirm order
          </button>
        </div>
      </div>
    </div>
  );
};

// <-- Logic End -->
