import {formikHandler} from '@src/Feature/Pages/QualificationPage/QualificationPage.PAPI';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {routes} from '../../../MVP/Main'

interface Prop_ {
  type: 'std' | 'navBar';
}

let query = null
export function useNext(back?: boolean) {
  
  const {pathname, search} = useLocation();
    if (!query && search) {
      query = search
    }
  return useMemo(() => {
    const max = routes.length - 1;
    const nextItem = routes.findIndex(route => route.path.toLowerCase().includes(pathname.toLowerCase())) + (back ? -1 : 1);
    const output = nextItem > max ? routes[0] : routes[nextItem];
    if(output?.path === '/Landing') output.path = `${output.path}${query}`;
    console.log({output, max, nextItem, pathname, routes})
    return  output;
  }, [pathname, back,query]);
}

export const NextButton: FC<Prop_> = ({type}) => {
  let isEnabled = true;
  const advanceTo = useNext();
  formikHandler?.submitForm().then((res) => {
    console.log({res})
  })
const canChange = formikHandler && !formikHandler.isValid
  console.log(`canChange: ${ canChange }`)
  console.dir({formikHandler})
  if (canChange) isEnabled = false;
  const navBarClasses = {
    className:
      'font-extrabold animate-bounce inline-block bg-primary-500 py-2 px-4 border border-transparent rounded-md text-base text-white hover:bg-opacity-75',
  };
  const stdClasses = {
    className:
      'font-extrabold animate-bounce inline-block bg-primary-500 py-2 px-4 border border-transparent rounded-md text-base text-white hover:bg-opacity-75',
  };
  const className = type === 'std' ? stdClasses : navBarClasses;
  return ( isEnabled && advanceTo && <Link to={advanceTo.path} {...className}> Next </Link> );
};
