import { atom } from 'recoil';

// <-- Import End -->
//
interface Guest {
	Name: string;
	'Date of Birth': string;
	'Party size': number;
	'Yearly annual income': number;
	'Zip code': number;
	'Marital Status': string
}

export const GUEST = atom<Guest>({
  key: 'guest',
  default: {} as Guest,
});
