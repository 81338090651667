import { FC, cloneElement } from "react";
import { nanoid } from "nanoid";
import { cloneDeep } from "lodash";
import "../../../MVP/Styles/FormCard/index.scss";
import { Field, Form, Formik, FormikProps } from "formik";
import { CRM_GUEST_DATA } from "@src/Core/States/Guest/CRM_GUEST_DATA.FMT";
import { useRecoilState } from "recoil";
import { GUEST } from "@src/Core/States/Guest/Guest.LX";
import Page from "@src/Feature/Shared/Page/Page.PAPI";
import { pageAnimation } from "@src/Styles/PageAnimations";
import { pageTransition } from "@src/Styles/PageTransitions";
import * as yup from 'yup'


const validation = yup.object().shape({
    'Marital Status': yup.string(),
    'Name': yup.string(),
    'Date of Birth': yup.string(),
    'Party Size': yup.number().required('Party Size is required'),
    'Yearly annual income': yup.number(),
})

export let formikHandler: FormikProps<any> = null;

export default function Qualifications() {
    //@ts-ignore
    const [data] = useRecoilState(CRM_GUEST_DATA);
    const [, setGuest] = useRecoilState(GUEST);
    //setGuest(data);
    console.log(data);
    return (
        <Formik
            enableReinitialize={true}
            initialValues={data}
            onSubmit={() => {}}
        >
            {(formik) => {
                formikHandler = formik;
                return (
                    <Page
                        animation={{
                            variants: pageAnimation,
                            transition: pageTransition,
                        }}
                        style={{ height: "100%" }}
                        className="background relative bg-white py-16 sm:py-24 lg:py-32"
                    >
                        <div className="QualificationsPage">
                            <Form className="MainForm" key="guestForm">
                                <SubForm>
                                    <DescriptiveHeading title="Who's Traveling">
                                        lorum ipsum dada da
                                    </DescriptiveHeading>
                                    <FormCard>
                                        <LabeledInput name="Zip code" />
                                        <LabeledInput
                                            name="Marital status"
                                            options={[
                                                "Legally Married",
                                                "Single Male",
                                                "Single Female",
                                                "Living Together",
                                            ]}
                                        />
                                        <LabeledInput name="Name" />
                                        <LabeledInput
                                            kind="date"
                                            name="Date of Birth"
                                        />
                                        <LabeledInput
                                            name="Party size"
                                            kind="number"
                                        />
                                        <LabeledInput
                                            kind="number"
                                            name="Yearly annual income"
                                        />
                                    </FormCard>
                                </SubForm>
                            </Form>
                        </div>
                    </Page>
                );
            }}
        </Formik>
    );
}

// The order of the children matters
export const SubForm: FC<any> = ({ children, style, className }) => {
    const classNames = className ? `${className} SubForm` : "SubForm";
    return (
        <div {...style} className={classNames}>
            {children.map((el, i) => {
                const elCopy = cloneElement(el, { key: nanoid() });
                return elCopy;
            })}
        </div>
    );
};

interface DescriptiveHeading__Props {
    title: string;
}

export const DescriptiveHeading: FC<DescriptiveHeading__Props> = ({
    title,
    children,
}) => {
    return (
        <div className="DescriptiveHeading">
            <p className="DescriptiveHeading__title">{title}</p>
            <p className="DescriptiveHeading__subTitle">{children}</p>
        </div>
    );
};

export const FormCard: FC<any> = ({ children }) => {
    return <div className="FormCard">{children}</div>;
};

interface LabeledInput__Props {
    name: string;
    options?: Array<string>;
    kind?: "number" | "date";
}

export const LabeledInput: FC<LabeledInput__Props> = ({
    name,
    options,
    kind,
}) => {
    const as = options ? "select" : undefined;
    const props = {
        as,
        type: kind ? kind : as ? undefined : "input",
    };

    return (
        <div className="LabeledInput">
            <label className="LabeledInput__label" htmlFor={name}>
                {name}
            </label>
            <br />
            <Field
                className="LabeledInput__Field"
                pattern="[0-9]"
                {...props}
                name={name}
            >
                {options &&
                    options.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
            </Field>
        </div>
    );
};
