import _ from 'lodash';
import { nanoid } from 'nanoid';
import { atom, selector } from 'recoil';

// <-- Import End -->

const KEYS_TO_MULTIPLY = ['price'];
const EXCLUDE_FROM_MULTIPLY = [null, undefined, -1];
type Cart_ = typeof cartExample;
interface OptionalProperties_ {
  options?: string[];
  preferred?: boolean;

  [key: string]: any;
}

const ProductExample = {
  id: 1,
  key: nanoid(),
  category: 'Vacation Activation',
  href: '#',
  numberOfNights: 2,
  title: 'Punta Cana',
  roomType: 'x room types available',
  description: 'Get some of the finest water the world has to',
  priceDescription: '$32.00',
  price: 32,
  // tax: 20,
  shipping: 2,
  quantity: 5,
  imageSrc:
    'https://www.quickanddirtytips.com/sites/default/files/styles/article_main_image/public/images/18306/is-vacay-a-word.jpg?itok=AZgVNRMS',
  imageAlt: "Front of men's Basic Tee in black.",
};

// const chargeExample = {
//   payload: 0,
//   value: 20,
//   currency: 'usd',
//   type: 'tax',
//   priceDescription: '$20.00 deposit',
// };

const product2 = _.cloneDeep(ProductExample);
product2.id = 2;
product2.key = nanoid();
product2.category = 'White Glove Service';
product2.title = 'Gold';
product2.description = 'One bedroom condo upgrade, priority confirmation';

const cartExample = {
  products: [ProductExample, product2] as Product_[],
  numberOfNights: 0,
  roomType: '',
  cardToken: '',
  subTotal: 0,
  taxes: 0,
  shipping: 0,
  total: 0,
};

const cartAtom = atom<Cart_>({
  key: 'cartAtom',
  default: cartExample,
});

// <-- Variable Definition End -->

export const cartSelector = selector({
  key: 'cartSelector',
  get: ({ get }) => {
    const ca = get(cartAtom);

    return updateAllTotals(ca);
  },
  set: ({ set }, newValue: Cart_) => {
    set(cartAtom, newValue);
  },
});

export type Product_ = typeof ProductExample & OptionalProperties_;

// <-- Logic End -->

function updateAllTotals(ca: Cart_) {
  const { products, cardToken } = ca;
  const lastProduct = products.length - 1;
  const { roomType } = products[lastProduct];
  const shipping = calculateTotal(products, 'shipping');
  const subTotal = calculateTotal(products, 'price');
  const numberOfNights = calculateTotal(products, 'numberOfNights');
  const taxes = subTotal * 0.1;
  const total = subTotal + taxes + shipping;

  return {
    subTotal,
    total,
    numberOfNights,
    cardToken,
    taxes,
    shipping,
    roomType,
    products,
  };

  function calculateTotal(products: Product_[], key: string) {
    return products.reduce((acc, cur) => {
      const { quantity } = cur;
      const { [key]: price } = cur;

      if (quantityMatters(quantity, key)) {
        return acc + price * quantity;
      }

      return acc + price;

      function quantityMatters(quantity: number, key: string) {
        const multiplyByKey = KEYS_TO_MULTIPLY.includes(key);
        const multiplyByQuantity = !EXCLUDE_FROM_MULTIPLY.includes(quantity);
        return multiplyByQuantity || multiplyByKey;
      }
    }, 0);
  }
}

// <-- Helper End -->
