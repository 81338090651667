import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Suspense, useEffect, useMemo, useState } from "react";
import * as Pages from "../Feature/Pages";
import { nanoid } from "nanoid";

//TODO: fix the loader to work as a fallback for the suspense property
//import MonsterLoader from "../demo/loader";

export const routes = [
    { path: "/Landing", suspend: false },
    { path: "/GuestInfo", suspend: false },
    { path: "/Destination", suspend: false },
    { path: "/CheckoutTemp", suspend: false },
];

export function Main() {
    const location = useLocation();

    const builtRoutes = useMemo(() => {
        return routes.map((route) => {
            const Component = Pages[route.path.replace("/", "")];
            return (
                    <Route key={route.path} path={route.path}>
                        <Suspense key={nanoid()} fallback={<h1>loading</h1>}>
                        <Component />
                        </Suspense>
                    </Route>
            );
        });
    }, []);

    return (
        <Switch location={location} key={location.pathname}>
            {builtRoutes}
        </Switch>
    );
}

function Loader() {
    const [s, ss] = useState(".");
    useEffect(() => {
        setTimeout(() => {
            if (s.length < 3) return ss((s) => s + ".");
            ss(".");
        }, 500);
    });

    return (
        <div className="flex justify-center align-middle">
            <div className="">Loading{s}</div>
        </div>
    );
}
