import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAeMHRd3muE4R9_Z_z6eRIYF1-ywBUdUwM',
  authDomain: 'selfserviceactivations.firebaseapp.com',
  projectId: 'selfserviceactivations',
  storageBucket: 'selfserviceactivations.appspot.com',
  messagingSenderId: '882449773767',
  appId: '1:882449773767:web:0d3cac45582dd9fdacfae2',
  measurementId: 'G-7PWXP5708M',
};

export const FIREBASE_CONFIG = initializeApp(firebaseConfig);
