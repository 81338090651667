import { CART } from '@src/Core/States/Cart/Cart.LX';
import { DATRIX_DESTINATIONS } from '@src/Core/States/Guest/DATRIX.FMT';
import { cloneDeep } from 'lodash';
import {nanoid} from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { pageAnimation } from '../../../Styles/PageAnimations';
import { pageTransition } from '../../../Styles/PageTransitions';
import Page from '../../Shared/Page/Page.PAPI';

// add an extra property on destinations to indicate if selected
// when click set all the flag properties in the array to false the destination selected will be set to tru
// when the property is true display calendar


const products = [
  {
    id: 1,
    name: 'Punta Cana ',
    href: '#',
    price: '$0',
    description:
      'Enjoy a tropical paradise nesled in one of the biggest harbors in the country.',
    options: '2 Room types available',
    imageSrc:
      'https://www.flytap.com/-/media/Flytap/new-tap-pages/destinations/north-america/dominican-republic/punta-cana/Images-Landing-Page-Destinations-punta-cana/PUJ_PagDestino_JUN2021_HeroeBannerMobile_1024x553.jpg',
    imageAlt: 'Punta Cana Dr.',
  },
  {
    id: 2,
    name: 'Branson',
    href: '#',
    price: '$0',
    description: 'The entertainment capital of the midwest',
    options: '3 Room Types Available',
    imageSrc:
      'https://i0.wp.com/bransontravel.com/wp-content/uploads/2020/09/aquarium.jpg',
    imageAlt: 'Branson Mo',
  },
  // More products...
];
export default function DestinationPage() {
  const [, cart] = useRecoilState(CART);
  function mapDevs(devs) {
    return false
  }
  //@ts-ignore
   const [_destinations] = useRecoilState(DATRIX_DESTINATIONS);
   console.log(_destinations)
  const destinations = useMemo(
    () =>
      _destinations.map((dest) => {
        const output = cloneDeep(dest);
        //@ts-ignore
        output.options = mapDevs(dest);
        return output;
      }),
    [_destinations]
  );
  const [fDestinations, setFDestinations] = useState(destinations)
   useEffect(() => {
     console.log(JSON.stringify(destinations));
   }, [destinations]);
  const history = useHistory();
  return (
    <Page
      animation={{ variants: pageAnimation, transition: pageTransition }}
      className='bg-white' 
      heading='Destinations'
      subHeading='Explore the world'
    >
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {fDestinations.map((dest) => (
            <div
              key={nanoid()}
              onClick={(e) => {
                //@ts-ignore
                if (!dest.options) {
                  console.log('rerender')
                  console.log(e.target)
                  //@ts-ignore
                  setFDestinations(destinations.map(d => dest.destName === d.destName ? {...d, options: true} : {...d, options: false}))
                }
              }}
              className='group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden'
            >
              <div className='aspect-w-3 aspect-h-4 bg-gray-200 group-hover:opacity-75 sm:aspect-none sm:h-96'>
                <img
                  src={dest.photoUrl}
                  alt='img'
                  className='w-full h-full object-center object-cover sm:w-full sm:h-full'
                />
              </div>
              <div className='flex-1 p-4 space-y-2 flex flex-col'>
                <h3 className='text-sm font-medium text-gray-900'>
                  <a href='#'>
                    {
                      ///@ts-ignore
                      dest.destName
                    }
                  </a>
                </h3>
                <p className='text-sm text-gray-500'>{dest.description}</p>
                <div className='flex-1 flex flex-col justify-end'>
                  <p className='text-sm italic text-gray-500'>{<input type='date'></input>}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
        <h2 className='sr-only'>Products</h2>
    </Page>
  );
}
